import React, { useState } from "react";
import styles from "./ContactUs.module.css";
import { Fade } from "react-awesome-reveal";
import VideoPopup from "../Popups/VideoPopup";

const ContactUs = () => {
	const [popupOpen, setPopupOpen] = useState(false);
	const videoLink = "https://www.youtube.com/embed/wUpe03gwncE?si=y-PgB3MvAmhOPZ3x";

	return (
		<section className={styles.container}>
			<div className={styles.content}>
				<div className={styles.contactBox}>
					<Fade triggerOnce direction="left">
						<h1>Need help?</h1>
						<h4>Don't hesitate to contact us.</h4>
						<button type="button">Contact Us</button>
					</Fade>
				</div>
				<div className={styles.right}>
					<button type="button" className={styles.playBtn} onClick={() => setPopupOpen(true)}>
						<img src="/home2/icons/playBtn.png" alt="play icon" />
					</button>
					{popupOpen && <VideoPopup videoLink={videoLink} onClose={() => setPopupOpen(false)} />}
				</div>
			</div>
		</section>
	);
};

export default ContactUs;
