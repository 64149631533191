import React from "react";
import Hero from "../../Component/Home2/HomePage/Hero";
import SubHero from "../../Component/Home2/HomePage/SubHero";
import Purpose from "../../Component/Home2/HomePage/Purpose";
import WhyUs from "../../Component/Home2/HomePage/WhyUs";
import Overview from "../../Component/Home2/HomePage/Overview";
import Calltoaction from "../../Component/Home2/HomePage/Calltoaction";
import ContactUs from "../../Component/Home2/Sections/ContactUs";
import Testimonial from "../../Component/Home2/HomePage/Testimonial";
import Footer2 from "../../Component/Home2/Footer/Footer";
import Header2 from "../../Component/Home2/Header/Header";

const Home2 = () => {
	return (
		<div className="home2">
			<Header2 />
			<Hero />
			<SubHero />
			<Purpose />
			<WhyUs />
			<Overview />
			<Testimonial />
			<Calltoaction />
			<ContactUs />
			<Footer2 />
		</div>
	);
};

export default Home2;
