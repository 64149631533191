import React from "react";
import Hero from "../../Component/Home2/Hero/Hero";
import WorkWithUs from "../../Component/Home2/CareerPage/WorkWithUs";
import ContactUs from "../../Component/Home2/Sections/ContactUs";
import Header2 from "../../Component/Home2/Header/Header";
import Footer2 from "../../Component/Home2/Footer/Footer";

const Career = () => {
	return (
		<div className="home2">
			<Header2 />
			<Hero
				videoLink="/home2/heros/careerVid.mp4"
				title={process.env.REACT_APP_APP_NAME}
				text="Help us build the future by empowering everyday people to generate income and control their wealth"
				height="660px"
			/>
			<WorkWithUs />
			<ContactUs />
			<Footer2 />
		</div>
	);
};

export default Career;
