import React from "react";
import Hero from "../../Component/Home2/Hero/Hero";
import AboutReal from "../../Component/Home2/RealstatePage/AboutReal";
import OurValues from "../../Component/Home2/Sections/OurValues";
import ContactUs from "../../Component/Home2/Sections/ContactUs";
import Header2 from "../../Component/Home2/Header/Header";
import Footer2 from "../../Component/Home2/Footer/Footer";

const Realstate = () => {
	return (
		<div className="home2">
			<Header2 />
			<Hero
				videoLink="/home2/heros/realstateVid.mp4"
				title="Real Estate"
				text={"You can now invest in real estate and own physical properties with " + process.env.REACT_APP_NAME + "."}
				height="590px"
			/>
			<AboutReal />
			<OurValues />
			<ContactUs />
			<Footer2 />
		</div>
	);
};

export default Realstate;
