import React, { useRef } from "react";
import styles from "./Testimonial.module.css";
import Comment from "../Cards/Comment";
import { Fade } from "react-awesome-reveal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";

const Testimonial = () => {
	const nextButtonRef = useRef(null);
	const prevButtonRef = useRef(null);

	const fakeData = [
		{
			id: 1,
			pic: "/home2/stockImages/user1.jpg",
			name: "Fresco",
			role: "Manager",
			message: "Be sure to invest with the right company always. There is no doubt about the credibility of this company. A very big Thank You to the Management.",
		},
		{
			id: 2,
			pic: "/home2/stockImages/user2.jpg",
			name: "Williams",
			role: "Entrepreneur",
			message:
				"Amazing work! This guys also very fast for support. No matter Sunday or Monday. I get my answers and they were really patiently with my sometimes stupid questions!",
		},
		{
			id: 3,
			pic: "/home2/stockImages/user3.jpg",
			name: "Samantha",
			role: "Business woman",
			message:
				"The ability to diversify your portfolio is a great feature. Investing into various services offered including NFTs is really awesome. I love the management.",
		},
		{
			id: 4,
			pic: "/home2/stockImages/user1.jpg",
			name: "Fresco",
			role: "Manager",
			message: "Be sure to invest with the right company always. There is no doubt about the credibility of this company. A very big Thank You to the Management.",
		},
		{
			id: 5,
			pic: "/home2/stockImages/user2.jpg",
			name: "Williams",
			role: "Entrepreneur",
			message:
				"Amazing work! This guys also very fast for support. No matter Sunday or Monday. I get my answers and they were really patiently with my sometimes stupid questions!",
		},
		{
			id: 6,
			pic: "/home2/stockImages/user3.jpg",
			name: "Samantha",
			role: "Business woman",
			message:
				"The ability to diversify your portfolio is a great feature. Investing into various services offered including NFTs is really awesome. I love the management.",
		},
	];
	return (
		<Fade triggerOnce direction="left">
			<section className={styles.container}>
				<div className={styles.content}>
					<div className={styles.title}>
						<h6>TESTIMONIAL</h6>
						<h2>What Clients Say</h2>
					</div>
					<div className={styles.carousel}>
						<Swiper
							slidesPerView={3}
							loop={true}
							spaceBetween={0}
							slidesPerGroupSkip={3}
							autoplay={{
								delay: 3000,
								disableOnInteraction: true,
							}}
							navigation={{
								nextEl: nextButtonRef.current,
								prevEl: prevButtonRef.current,
							}}
							breakpoints={{
								1150: {
									slidesPerView: 3,
									slidesPerGroup: 3,
								},
								720: {
									slidesPerView: 2,
									slidesPerGroup: 2,
								},
								100: {
									slidesPerView: 1,
									slidesPerGroup: 1,
								},
							}}
							modules={[Navigation, Autoplay]}
							className="mySwiper"
						>
							{fakeData ? (
								fakeData.map((item) => (
									<SwiperSlide key={item.id}>
										<Comment data={item} />
									</SwiperSlide>
								))
							) : (
								<p>loading...</p>
							)}
							<div ref={prevButtonRef}>
								<img className="swiper-button-prev" src="/home2/icons/prev.svg" alt="Right Arrow Icon" style={{ width: "20px" }} />
							</div>
							<div ref={nextButtonRef}>
								<img className="swiper-button-next" src="/home2/icons/next.svg" alt="Left Arrow Icon" style={{ width: "20px" }} />
							</div>
						</Swiper>
					</div>
				</div>
			</section>
		</Fade>
	);
};

export default Testimonial;
