import React from "react";
import styles from "./Purpose.module.css";
import { Bounce } from "react-awesome-reveal";
import { Fade } from "react-awesome-reveal";

const Purpose = () => {
	return (
		<section className={styles.container}>
			<div className={styles.content}>
				<div className={styles.title}>
					<Bounce triggerOnce direction="left">
						<div>
							<h6>OUR PURPOSE</h6>
							<h2>Our common Purpose is Creating opportunities to rise.</h2>
						</div>
					</Bounce>
				</div>
				<Fade triggerOnce direction="up">
					<div className={styles.cardsList}>
						<div>
							<div className={styles.card}>
								<div className={styles.imageContainer}>
									<img src="/home2/stockImages/purpose1.jpg" alt="handshake" />
								</div>
								<div>
									<h3>Explore With Us</h3>
									<p>
										Seize the opportunity to grow your capital in the cryptocurrency market by copying the trades of top preforming traders in our
										investment programme
									</p>
								</div>
							</div>
							<div className={styles.card}>
								<div className={styles.imageContainer}>
									<img src="/home2/stockImages/purpose3.jpg" alt="high frequency trading" />
								</div>
								<div>
									<h3>High Frequency Trading</h3>
									<p>
										Global institutions, leading hedge funds and industry innovators turn to {process.env.REACT_APP_APP_NAME} for bitcoin cryptocurrency trading advice
										and market-making services.
									</p>
								</div>
							</div>
						</div>
						<div>
							<div className={styles.card}>
								<div className={styles.imageContainer}>
									<img src="/home2/stockImages/purpose2.jpg" alt="co-workers" />
								</div>
								<div>
									<h3>Investment Management</h3>
									<p>
										We deliver active bitcoin investment strategies across public and private markets and custom solutions to institutional and
										individual investors.
									</p>
								</div>
							</div>

							<div className={styles.card}>
								<div className={styles.imageContainer}>
									<img src="/home2/stockImages/purpose4.jpg" alt="city" />
								</div>
								<div>
									<h3>Wealth Management</h3>
									<p>We help people, businesses and institutions build, preserve and manage wealth so they can pursue their financial goals.</p>
								</div>
							</div>
						</div>
					</div>
				</Fade>
			</div>
		</section>
	);
};

export default Purpose;
