import React from "react";
import styles from "./WhyUs.module.css";
import { Fade } from "react-awesome-reveal";
import { Bounce } from "react-awesome-reveal";

const WhyUs = () => {
	return (
		<section className={styles.container}>
			<div className={styles.content}>
				<Fade triggerOnce direction="left">
					<h6>WHY CHOOSE US</h6>
				</Fade>
				<Fade triggerOnce direction="right">
					<h2>Smart Investing with Smart Ideas</h2>
				</Fade>
				<Fade triggerOnce direction="up">
					<p>{process.env.REACT_APP_APP_NAME} have a unique approach to income generation. We build innovative wealth creation products.</p>
				</Fade>
				<div className={styles.cardsLists}>
					<Bounce triggerOnce direction="down" cascade damping={0.2}>
						<div className={styles.card}>
							<div className={styles.imgContainer}>
								<img src="/home2/icons/shield.svg" alt="shield icon" />
							</div>
							<h3>Security & Privacy</h3>
							<p>We use the latest technology to protect your information and financial transactions</p>
						</div>
						<div className={styles.card}>
							<div className={styles.imgContainer}>
								<img src="/home2/icons/squarePen.svg" alt="square pen icon" />
							</div>
							<h3>Investing Tools</h3>
							<p>From strategy to analysis, our tools help you keep track of your finances with ease.</p>
						</div>
						<div className={styles.card}>
							<div className={styles.imgContainer}>
								<img src="/home2/icons/lock.svg" alt="shield icon" />
							</div>
							<h3>Asset Protection</h3>
							<p>We also participate in asset protection programs to further secure your funds.</p>
						</div>
						<div className={styles.card}>
							<div className={styles.imgContainer}>
								<img src="/home2/icons/chartBar.svg" alt="chart bar icon" />
							</div>
							<h3>Investment Access</h3>
							<p>We are providing investors access to investments previously available to the top 1%.</p>
						</div>
					</Bounce>
				</div>
			</div>
		</section>
	);
};

export default WhyUs;
