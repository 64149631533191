import React from "react";
import styles from "./OurValues.module.css";
import { Fade } from "react-awesome-reveal";

const OurValues = () => {
	return (
		<section className={styles.container}>
			<div className={styles.overlay}>
				<div className={styles.left}></div>
				<div className={styles.right}></div>
			</div>
			<img src="/home2/stockImages/buildings.jpg" alt="buildings" />
			<div className={styles.content}>
				<Fade triggerOnce direction="left">
					<h6 style={{ color: "white" }}>COMPANY VALUE</h6>
				</Fade>
				<Fade triggerOnce direction="right">
					<h2 style={{ color: "white" }}>Its all about your Future</h2>
				</Fade>
				<Fade triggerOnce direction="up">
					<p style={{ color: "white" }}>We focus on comprehensive financial advice and investment services</p>
				</Fade>
				<div className={styles.cardsLists}>
					<Fade triggerOnce direction="up" cascade damping={0.2}>
						<div className={styles.card}>
							<h3>Vision</h3>
							<p>
								In a fast-moving and increasingly complex global economy, our success depends on how faithfully we adhere to our core principles:
								delivering exceptional client service and acting with integrity.
							</p>
						</div>
						<div className={styles.card}>
							<h3>Mission</h3>
							<p>
								Our mission is to generate world-class investment returns over the long term. We aspire to do so in a way that makes our partners and
								portfolio companies proud, as we build a unique, global company.
							</p>
						</div>
						<div className={styles.card}>
							<h3>Guarantee</h3>
							<p>
								We are here because we are passionate about open, transparent markets and aim to be a major driving force in widespread adoption, we are
								the first and the best in investment management.
							</p>
						</div>
					</Fade>
				</div>
			</div>
		</section>
	);
};

export default OurValues;
