import React from "react";
import styles from "./InvestmentPlan.module.css";
import Heading, { InsuranceHeading } from "../../Component/InvestmentPlan/Heading/Heading";
import AllPlan from "../../Component/InvestmentPlan/AllPlans/AllPlan";
import { InsurancePlans } from "../../Component/InvestmentPlan/AllPlans/AllPlan";
import TransferBalance from "../../Component/OurPlans/Popup/TransferBalance";
import { useState } from "react";
const InvestmentPlan = ({ userInfo, getUserInfo }) => {
  const [transferFunds, setTransferFunds] = useState(false);
  const [planDetails, setPlanDetails] = useState({});
  const [isInsured, setIsInsured] = useState(false);
  async function openModalWithPlanDetails(details) {
    setTransferFunds(true);
    setPlanDetails(details);
    setIsInsured(details.insured);
    console.log(details, "details");
  }
  return (
    <div className={styles.wrapper}>
      <Heading />
      <AllPlan setTransferFunds={setTransferFunds} openModalWithPlanDetails={openModalWithPlanDetails} />


      {/* <InsuranceHeading />
      <InsurancePlans setTransferFunds={setTransferFunds} openModalWithPlanDetails={openModalWithPlanDetails} /> */}
      {transferFunds && <TransferBalance isInsured={isInsured} getUserInfo={getUserInfo} userInfo={userInfo} planDetails={planDetails} setPopup={setTransferFunds} />}
    </div>
  );
};

export default InvestmentPlan;
