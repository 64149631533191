import React from "react";
import styles from "./WorkWithUs.module.css";
import { Fade } from "react-awesome-reveal";

const WorkWithUs = () => {
	return (
		<section>
			<Fade triggerOnce direction="left">
				<div className={styles.content}>
					<div className={styles.left}>
						<h6>Work With Us</h6>
						<h2>Become a {process.env.REACT_APP_APP_NAME} staff</h2>
						<p>
							At {process.env.REACT_APP_APP_NAME}, each of us is a captain of our spaceship and free to come up with new ideas to improve our journey. We’re empowered to
							make decisions for ourselves, for our team, and even for our leaders in order to innovate and build trust. Join us in our mission to give our
							customers greater choice, independence, and opportunity.
						</p>
						<p>
							Submit your application through our site. If your skills appear to be a good match for the role, you’ll hear from us regarding next steps. If
							you don’t move forward in the process at this time, there are always other opportunities!
						</p>
						<button type="button" className={styles.ctaBtn}>
							Submit Resume <img src="/home2/icons/arrowRight.png" alt="arrow icon" />
						</button>
					</div>
					<div className={styles.right}>
						<div>
							<img src="/home2/stockImages/career.jpg" alt="investment" />
						</div>
					</div>
				</div>
			</Fade>
		</section>
	);
};

export default WorkWithUs;
