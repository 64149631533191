import React from "react";
import DataTable from "../../Component/LeaderBoard/DataTable";

const LeaderBoardPage = () => {
  return (
    <>
      <DataTable />
    </>
  );
};

export default LeaderBoardPage;
