import React from "react";
import styles from "./AboutReal.module.css";
import { Fade } from "react-awesome-reveal";

const AboutReal = () => {
	return (
		<section className={styles.container}>
			<Fade triggerOnce direction="left">
				<div className={styles.content}>
					<div className={styles.left}>
						<h6>ABOUT REAL ESTATE</h6>
						<h2>Create Passive Income Through Real Estate</h2>
						<p>Real estate investing involves the purchase, ownership, management, rental and/or sale of real estate for profit.</p>
						<p>
							Real estate is an asset form with liquidity relative to other investments, it is also capital intensive and is highly cash flow dependent. If
							these factors are not well understood and managed by the investor, real estate becomes a risky investment. This is why investing with Quantum
							Finance is the best option, we don't only make real estate investment cost effective, every risk involved is also curtailed leaving you with
							guaranteed profits.
						</p>

						<a href="/signup">
							<button type="button" className={styles.ctaBtn}>
								Start Membership <img src="/home2/icons/arrowRight.png" alt="arrow icon" />
							</button>
						</a>
					</div>
					<div className={styles.right}>
						<div>
							<img src="/home2/stockImages/realstate.jpg" alt="company meeting" />
						</div>
					</div>
				</div>
			</Fade>
		</section>
	);
};

export default AboutReal;
