import React from "react";
import Heading from "../../Component/MyStakes/Heading/Heading";
import BackButton from "../../Component/BackButton/BackButton";
import Info from "../../Component/MyStakes/Info/Info";
import Actions from "../../Component/MyStakes/Actions/Actions";
import Transaction from "../../Component/MyStakes/Transaction/Transaction";
import styles from "./styles.module.css";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
const MyStakes = () => {
  let { id } = useParams();
  console.log(id);



  const [investData, setInvestData] = useState({});
  async function getInvestments() {
    let api = process.env.REACT_APP_API_URL;
    let user_id = Cookies.get("auth-token");
    let { data } = await axios.get(api + "/user/get-investments/" + id, {
      headers: {
        "Content-Type": "application/json",
        "client-id": "Sentry " + process.env.REACT_APP_CLIENT_ID,
        Authorization: "Bearer " + user_id,
      },
    });
    console.log(data);
    setInvestData(data.data);

  }

  useEffect(() => {
    getInvestments();
  }
    , []);

  return (
    <div className={styles.wrapper}>
      <div>
        <BackButton text="Home" />
        <Heading investData={investData} />
      </div>
      <Info investData={investData} />
      <Actions investData={investData} />
      {/* <Transaction /> */}
    </div>
  );
};

export default MyStakes;
