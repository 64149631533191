import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import styles from "./Chart.module.css";

const Chart = () => {
  const chartRef = useRef(null);

  useEffect(() => {
    const investmentData = [
      100, 150, 200, 180, 250, 300, 280, 100, 150, 200, 180, 250, 300, 280,
    ];
    const profitData = [
      50, 80, 100, 90, 120, 150, 500, 50, 80, 100, 90, 120, 150, 130,
    ];

    const options = {
      legend: {
        labels: {
          colors: "#f2fbffba",
        },
        position: "top",
        horizontalAlign: "left",
        offsetY: 10,
        offsetX: -10,
        markers: {
          radius: 0,
        },
      },
      series: [
        {
          name: "Support",
          data: investmentData,
          type: "area",
          stroke: {
            curve: "smooth",
            colors: ["#816BFF"],
            width: 1,
          },
          fill: {
            colors: ["#816BFF"],
            opacity: 0.01,
          },
        },
        {
          name: "Profit",
          data: profitData,
          type: "area",
          stroke: {
            curve: "smooth",
            colors: ["#BD68FF"],
            width: 1,
          },
          fill: {
            colors: ["#BD68FF"],
            opacity: 0.01,
          },
        },
      ],
      chart: {
        fontFamily: "'IBM Plex Sans'",
        fontSize: "14px",
        height: "150px",
        width: "100%",
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#d3bb0d", "#0dd39e"],
      xaxis: {
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      grid: {
        row: {
          show: false,
        },
        borderColor: "transparent",
        showBottomBorder: false,
      },
    };

    const chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, []);

  return <div id="chart" className={styles.chart} ref={chartRef}></div>;
};

export default Chart;
