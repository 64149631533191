import React, { useEffect } from "react";
import Heading from "../../Component/OurPlans/Heading/Heading";
import BackButton from "../../Component/BackButton/BackButton";

import styles from "./styles.module.css";
import InvestmentAccount from "../../Component/OurPlans/InvestmentAccount/InvestmentAccount";
import AmountInvested from "../../Component/OurPlans/AmountInvested/AmountInvested";
import ActivePlan from "../../Component/OurPlans/ActivePlan/ActivePlan";
import { useState } from "react";
import TransferBalance from "../../Component/OurPlans/Popup/TransferBalance";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
const OurPlans = ({ userInfo }) => {
  const navigate = useNavigate();
  const [transferFunds, setTransferFunds] = useState(false);
  let user_id = Cookies.get("auth-token");
  useEffect(() => {
    if (user_id === "undefined" || !user_id) {
      navigate("/login");
    }
  }, []);
  return (
    <>
      <div className={styles.wrapper}>
        <BackButton text="Home" />
        <Heading />
        <div className={styles.investmentInfo}>
          <InvestmentAccount setTransferFunds={setTransferFunds} userInfo={userInfo} />
          <AmountInvested userInfo={userInfo} />
        </div>
        <ActivePlan />
        {/* <ActivePlan /> */}
      </div>
      {transferFunds && <TransferBalance setPopup={setTransferFunds} />}
    </>
  );
};

export default OurPlans;
