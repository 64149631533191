import React from "react";
import styles from "./VideoPopup.module.css";

const VideoPopup = ({ videoLink, onClose }) => {
	const handleClose = () => {
		onClose();
	};

	return (
		<div className={styles.popupContainer}>
			<div className={styles.backdrop} onClick={handleClose}>
				<span className={styles.closeIcon} onClick={handleClose}>
					&times;
				</span>
			</div>
			<div className={styles.popupContent}>
				<iframe
					width="100%"
					height="100%"
					src={videoLink}
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen
				></iframe>
			</div>
		</div>
	);
};

export default VideoPopup;
