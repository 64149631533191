import React, { useState } from "react";
import formatNumber from "../../utils";

import Toggle from "./Toggle/Toggle";
import styles from "./styles.module.css";

const SinglePlan = ({
  name,
  description,
  min_amount,
  max_amount,
  interest_rate,
  possible_interest_rates,
  possible_interest_rates_for_insurance,
  possible_durations,
  interest_schedule,
  status,
  background,
  refund_capital,
  bg,
  id,
  setTransferFunds,
  openModalWithPlanDetails,
}) => {
  const [active, setActive] = useState(false);
  let insured = active;

  let first_duration = possible_durations ? possible_durations[0] : 0;
  let last_duration = possible_durations ? possible_durations[possible_durations.length - 1] : 0;

  let first_interest_rate = possible_interest_rates ? possible_interest_rates[0] : 0;
  let last_interest_rate = possible_interest_rates ? possible_interest_rates[possible_interest_rates.length - 1] : 0;

  return (
    <div className={styles.cardWrapper}>
    <div
      className={styles.card}
      // style={{ backgroundImage: `url(${bg})` }}
    >
      <h4 className={styles.name}>{name}</h4>
      <div className={styles.box}>
        <div className={styles.topBox}>
          <div className={styles.interestBox}>
            <div className={styles.amountBox}>
              <h5 className={styles.amount}>
                {possible_interest_rates[0]}%
              </h5>
              <p className={styles.tagline}>{interest_schedule} Interest</p>
            </div>
          </div>
          <div className={styles.weekBox}>
            <div className={styles.amountBox}>
              <h5 className={styles.amount}>{possible_durations[0]}</h5>
              <p className={styles.tagline}>{
                possible_durations[0] > 1 ? "Days" : "Day"
                // interest_schedule === "Daily" ? "Days" :  interest_schedule === "Weekly" ? "Weeks" : interest_rate === "Monthly" ? interest_rate ==="Hourly" ? "Hours" : "Months" : "Years"

              }</p>
            </div>
          </div>
        </div>
        <div className={styles.infoContainer}>
          <div className={styles.info}>
            <p className={styles.text}>Minimum Deposit</p>
              <span className={styles.seperator}>-</span>
              <p className={[styles.text, styles.value].join(" ")}>
                <span className={styles.highlight}>
                  {min_amount / Math.pow(10, 8)}
                </span>{" "}
                <span className={styles.currency}>usd</span>
              </p>
            </div>
            <div className={styles.info}>
              <p className={styles.text}>Maximum Deposit</p>
              <span className={styles.seperator}>-</span>
              <p className={[styles.text, styles.value].join(" ")}>
                <span className={styles.highlight}>
                  {max_amount < 1 ? "Unlimited" : max_amount / Math.pow(10, 8)}
                </span>{" "}
                <span className={styles.currency}>
                  {max_amount < 1 ? "" : "usd"}
                </span>
              </p>
            </div>
            <div className={styles.info}>
              <p className={styles.text}>Term Duration</p>
              <span className={styles.seperator}>-</span>
              <p className={[styles.text, styles.value].join(" ")}>
                <span className={styles.highlight}>{possible_durations[0] }</span>{" "}
                {possible_durations[0] > 1 ? "Days" : "Day"}
                {/* {interest_schedule === "Daily"
                  ? "Days"
                  : interest_schedule === "Weekly"
                    ? "Weeks"
                    : interest_rate === "Monthly"
                      ? interest_rate === "Hourly"
                        ? "Hours"
                        : "Months"
                      : "Years"} */}
              </p>
            </div>
            <div className={styles.info}>
              <p className={styles.text}>Capital Return </p>
              <span className={styles.seperator}>-</span>
              <p className={[styles.text, styles.value].join(" ")}>
                {refund_capital ? "YES" : "NO"}
              </p>
            </div>
            <div className={styles.info}>
              <p className={styles.text}>Status</p>
              <span className={styles.seperator}>-</span>
              <p className={[styles.text, styles.value].join(" ")}>
                <span className={styles.highlight}>{status}</span>
              </p>
            </div>
            {/* <div className={styles.info}>
              <p className={styles.text}>Insurance </p>
              <span className={styles.seperator}>-</span>
              <Toggle
                id={`status ${id}`}
                enable={active}
                setEnable={
                  (e) => {
                    setActive(e)
                    localStorage.setItem("enabled-insurance", true);
                  }
                }
                color={bg}

              />
            </div> */}
          </div>
        </div>
      </div>{" "}
      <button
        onClick={() => {
          openModalWithPlanDetails({
            name,
            id,
            description,
            min_amount,
            max_amount,
            possible_durations,
            possible_interest_rates,
            possible_interest_rates_for_insurance,
            interest_rate,
            first_duration,
            interest_schedule,
            status,
            background,
            refund_capital,
            bg,
            insured,
          });
          // setTransferFunds(true)
        }}
        className={styles.button}
      >
        Investment Now
      </button>
    </div>
  );
};

export default SinglePlan;
