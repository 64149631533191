import React from "react";
import styles from "./Hero.module.css";
import { Fade } from "react-awesome-reveal";

const Hero = ({ videoLink, title, text, height }) => {
	return (
		<section>
			<div className={styles.container} style={{ height: height }}>
				<div className={styles.overlay}></div>
				<video autoPlay muted loop className={styles.videoBackground}>
					<source src={videoLink} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
				<div className={styles.content}>
					<Fade triggerOnce direction="up" duration={1900}>
						<div className={styles.box}>
							<h1>{title}</h1>
							<h5>{text}</h5>
						</div>
					</Fade>
				</div>
			</div>
		</section>
	);
};

export default Hero;
