import React from "react";
import styles from "./Overview.module.css";
import { Fade } from "react-awesome-reveal";

const Overview = () => {
	return (
		<section className={styles.container}>
			<Fade triggerOnce direction="up">
				<div className={styles.content}>
					<h6>OVERVIEW</h6>
					<h2>Start a Good Plan</h2>
					<p>Think about what you want, make the best out of it by starting with us today. we are always available to make the right choice for you.</p>
					<p>Our experts are the best in everything. They are well trained in forex, crypto-currency trading and also in giving investment advices.</p>
				</div>
			</Fade>
		</section>
	);
};

export default Overview;
