import React, { useState } from "react";
import styles from "./Hero.module.css";
import VideoPopup from "../Popups/VideoPopup";
import { Fade } from "react-awesome-reveal";

const Hero = () => {
	const [popupOpen, setPopupOpen] = useState(false);
	const videoLink = "https://www.youtube.com/embed/wUpe03gwncE?si=y-PgB3MvAmhOPZ3x";

	return (
		<section>
			<div className={styles.container}>
				<video autoPlay muted loop className={styles.videoBackground}>
					<source src="/home2/heros/homeVid.mp4" type="video/mp4" />
					Your browser does not support the video tag.
				</video>
				<div className={styles.overlay}></div>
				<div className={styles.content}>
					<div className={styles.left}>
						<Fade triggerOnce direction="up" cascade>
							<h1>International Private Equity Company</h1>
							<a href="/signup">
								<button type="button" className={styles.ctaBtn}>
									Start Membership <img src="/home2/icons/arrowRight.png" alt="arrow icon" />
								</button>
							</a>
						</Fade>
					</div>
					<div className={styles.right}>
						<button type="button" className={styles.playBtn} onClick={() => setPopupOpen(true)}>
							<img src="/home2/icons/playBtn.png" alt="play icon" />
						</button>
						<h6>PLAY INTRO</h6>
						{popupOpen && <VideoPopup videoLink={videoLink} onClose={() => setPopupOpen(false)} />}
					</div>
				</div>
			</div>
		</section>
	);
};

export default Hero;
