import React from "react";
import styles from "./Actions.module.css";
import SingleAction from "./SingleAction";

const Actions = ({ investData = { investData } }) => {
  const actionData = [

    {
      title: "My Investment Amount",
      value: investData.amount / Math.pow(10, 8),
      inUsd: investData.amount / Math.pow(10, 8),
      buttonText: "Withdraw".toUpperCase(),
    },
    {
      title: "Accumulated Profits",
      value: "0",
      inUsd: "0",
      buttonText: "Claim rewards".toUpperCase(),
    },
  ];
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>Actions</h3>
      <div className={styles.boxWrapper}>
        {actionData.map((el, i) => (
          <SingleAction {...el} key={i} />
        ))}
      </div>
    </div>
  );
};

export default Actions;
