import React from "react";
import styles from "./Comment.module.css";

const Comment = (props) => {
	const { pic, name, role, message } = props?.data || {};
	return (
		<div className={styles.container}>
			<div className={styles.message}>
				<p>{message}</p>
			</div>
			<div className={styles.user}>
				<img src={pic} alt={name} />
				<div>
					<span>{name}</span>
					<span>{role}</span>
				</div>
			</div>
		</div>
	);
};

export default Comment;
