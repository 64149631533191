import React from "react";
import styles from "./SubHero.module.css";
import { Bounce } from "react-awesome-reveal";

const SubHero = () => {
	return (
		<section className={styles.container}>
			<div className={styles.content}>
				<Bounce triggerOnce direction="up" cascade damping={0.2}>
					<div className={styles.card}>
						<img src="/home2/icons/userSolid.svg" alt="user icon" />
						<div>
							<h3>Membership Registration</h3>
							<p>First you need to become a member of {process.env.REACT_APP_APP_NAME} by registering.</p>
						</div>
					</div>
					<div className={styles.card}>
						<img src="/home2/icons/chartPie.svg" alt="money icon" />
						<div>
							<h3>Choose Plan</h3>
							<p>Select any of our unique financial earning plans that best works for you.</p>
						</div>
					</div>
					<div className={styles.card}>
						<img src="/home2/icons/moneyBill.svg" alt="user icon" />
						<div>
							<h3>Passive Income</h3>
							<p>Start earning passively and get paid immediately you request for a withdrawal.</p>
						</div>
					</div>
				</Bounce>
			</div>
		</section>
	);
};

export default SubHero;
