import React from "react";
import styles from "./AboutCompany.module.css";
import { Fade } from "react-awesome-reveal";

const AboutCompany = () => {
	return (
		<section className={styles.container}>
			<Fade triggerOnce direction="left">
				<div className={styles.content}>
					<div className={styles.left}>
						<h6>ABOUT COMPANY</h6>
						<h2>For a secure and planned future</h2>
						<p>
							{process.env.REACT_APP_APP_NAME} is one of the largest and most experienced international private equity firms. We have an established team of investment
							professionals, who are focused mainly on investment.
						</p>
						<p>
							{process.env.REACT_APP_APP_NAME} is run by a team of trading experts who generate profits by buying and selling currencies, stocks, options and commodities on
							the foreign exchange market. We employ a variety of trading techniques to achieve the set goals for the client.
						</p>
						<p>
							{process.env.REACT_APP_APP_NAME} consists a team of financial market professionals assembled specifically in order to provide the best possible trading
							conditions to its customers. Our specialists have been involved in the development of technical specification for designing an up-to-date
							platform that is suitable for both beginners and experienced traders.
						</p>
						<p>
							As long as we have existed we have been trying to leverage lower risk and higher profits for our customers through innovative and insightful
							analysis, information dispersion, and expert assistance. Our team of professionals is composed of experienced and skilled experts and
							professionals, who bring a diverse and in depth knowledge to the entire investing process.
						</p>
						<p>
							Our multi-asset investment approach is aimed at what matters helping you reach your desired outcomes. Portfolio management is the heart of
							what we do. From our current research studying the market, we are expecting to trigger a long term investment trading strategy which would
							give our new members guaranteed return on investment for the stipulated time.
						</p>
						<a href="/signup">
							<button type="button" className={styles.ctaBtn}>
								Start Membership <img src="/home2/icons/arrowRight.png" alt="arrow icon" />
							</button>
						</a>
					</div>
					<div className={styles.right}>
						<div>
							<img src="/home2/stockImages/company.jpg" alt="company meeting" />
						</div>
					</div>
				</div>
			</Fade>
		</section>
	);
};

export default AboutCompany;
