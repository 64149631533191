import React from "react";
import styles from "./Calltoaction.module.css";
import { Fade } from "react-awesome-reveal";

const Calltoaction = () => {
	return (
		<section>
			<div className={styles.content}>
				<Fade triggerOnce direction="left">
					<div className={styles.left}>
						<h2> Let Us Work For You.</h2>
						<p>We help individuals, businesses and institutions build, preserve and manage wealth so they can pursue their financial goals.</p>
						<a href="/signup">
							<button type="button" className={styles.ctaBtn}>
								Start Membership <img src="/home2/icons/arrowRight.png" alt="arrow icon" />
							</button>
						</a>
					</div>
				</Fade>
				<Fade triggerOnce direction="up">
					<div className={styles.right}>
						<div>
							<img src="/home2/stockImages/investment.jpg" alt="investment" />
						</div>
					</div>
				</Fade>
			</div>
		</section>
	);
};

export default Calltoaction;
