import React from "react";
import styles from "./InvestmentAccount.module.css";

const InvestmentAccount = ({ setTransferFunds, userInfo}) => {
  const formatNumber = (number) =>
    number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // balance is stored in backend raised to pow of 8
    // so we divide by 10^8 to get the actual balance

let balance = userInfo?.balance;
  return (
    <div className={styles.investmentAccount}>
      <h4 className={styles.title}>Balance </h4>
      <div className={styles.amountContainer}>
        <div>
          <h4 className={styles.availableAmount}>
            {balance} <span className={styles.currency}>USD</span>{" "}
          </h4>
          <p className={styles.text}>Available Funds</p>
        </div>
        <div>
          <h4 className={styles.lockedAmount}>{userInfo?.currently_invested ? userInfo.currently_invested / Math.pow(10, 8) + " USD": "0"}</h4>
          <p className={styles.text}>Currently Invested</p>
        </div>
      </div>
      <button className={styles.button} onClick={
        () => {
          window.location.href = "/plans"
        }
        }>
       View Plans
      </button>
    </div>
  );
};

export default InvestmentAccount;
